// custom CSS styles
import "./src/css/style.scss"
import { gsap } from "gsap"

export const onClientEntry = () => {
    document.fonts.ready.then(function (e) {
        const header = document.querySelector('header');
        document.body.classList.add("loaded")
        gsap.to(header, {
            autoAlpha: 1,
            top: 0,
            ease: "power2",
            duration: 0.4,
            onComplete: () => {
                document.body.classList.add("navbar-loaded");
            }
        })
    })
}

export const onRouteUpdate = ({location, prevLocation}) => {

    // console.log('onRouteUpdate');
    // console.log('new pathname', location.pathname)
    // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
    //
    // document.querySelector("body").classList.remove('mobile-opened');
    //
    // switch(location.pathname){
    //     case '/work/': {

            // if(isBrowser) {
            //
            //     const filters = document.querySelectorAll('.filters');
            //     filters.forEach((elm) => {
            //         elm.addEventListener('mousemove', (e) => {
            //
            //             const cntWd = elm.offsetWidth;
            //             const tb = elm.firstChild;
            //             const sldWd = tb.offsetWidth;
            //
            //             // console.log(tb);
            //             // let obj = document.querySelector(e);
            //             // console.log(elm);
            //             // let obj = elm.firstChild;
            //             // console.log(obj);
            //             // obj.css({left: ((cntWd - sldWd)*((e.pageX / cntWd).toFixed(3))).toFixed(1) +"px" });
            //             console.log('cntWd: ' + cntWd);
            //             console.log('sldWd: ' + sldWd);
            //             console.log('e: ' + e.pageX);
            //             let left = ((cntWd - sldWd) * (e.pageX / cntWd));
            //             console.log(left);
            //
            //             gsap.to(tb, 0.25, {
            //                 left: left
            //             });
            //         });
            //     })
            // }

    //         break;
    //     }
    //
    //     default: {
    //
    //     }
    // }
}

